import { InfoLabel } from 'components/InfoLabel';
import './PowerCalculatorTotalResult.scss';
import { FC } from 'react';
import { CalculateResponse } from 'types/powerCalculator';
import { getFormattedNumber } from 'utils/data';

const descriptionFormulaVCPU = 'Суммарная нагрузка по CPU по всем VM с учетом дополнительных ресурсов на новую VM, пересчитанная в физические ядра с учетом потоков'
const descriptionFormulaPCPU = 'Суммарная нагрузка по CPU по всем VM с учетом дополнительных ресурсов на новую VM, пересчитанная в виртуальные ядра с учетом потоков'
const descriptionFormulaOther = (indicatorName: string) => `Суммарная нагрузка ${indicatorName} по всем VM с учетом дополнительных ресурсов на новую VM`


const PowerCalculatorTotalResult: FC<{ data: CalculateResponse }> = ({ data }) => {

  return <>
  <span>Итог: </span>  
  <div className='power-calculator-total-result'>
    <div className='power-calculator-total-result__indicators' >
      <div className='power-calculator-total-result__indicator'> <InfoLabel htmlFor='pCpu' children={null} description={descriptionFormulaVCPU}/><strong id='pCpu'> pCPU (физ.ядра):</strong> {getFormattedNumber(Math.ceil(data.totalResult.cpu / 100 / 2))}  </div>  |  
      <div className='power-calculator-total-result__indicator'> <InfoLabel htmlFor='vCpu' children={null} description={descriptionFormulaPCPU}/><strong id='vCpu'> vCPU (вирт.ядра):</strong> {getFormattedNumber(Math.ceil(data.totalResult.cpu / 100))}  </div>  |  
      <div className='power-calculator-total-result__indicator'> <InfoLabel htmlFor='ram'  children={null} description={descriptionFormulaOther('RAM')}/><strong id='ram'> RAM (МВ):</strong>   {getFormattedNumber(data.totalResult.ram)} </div> | 
      <div className='power-calculator-total-result__indicator'> <InfoLabel htmlFor='hdd'   children={null} description={descriptionFormulaOther('HDD')} /><strong id='hdd'> HDD (МВ):</strong>  {getFormattedNumber(data.totalResult.hdd || 0)} </div> | 
      <div className='power-calculator-total-result__indicator'> <InfoLabel htmlFor='gpu'  children={null} description={descriptionFormulaOther('GPU')} /><strong id='gpu'> GPU (МВ):</strong>  {getFormattedNumber(data.totalResult.gpu || 0)} </div>  
    </div>
    <div id='report_controls'/>
  </div>
  </>
}

export default PowerCalculatorTotalResult;
